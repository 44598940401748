import React from 'react';
import { newsList } from '../constants/news';
import Heading from '../components/atoms/Heading';
import StaticBanner from '../components/organisms/StaticBanner';
import Link from '../components/atoms/Link';
import Paragraph from '../components/atoms/Paragraph';
const News = () => {
    return (
        <StaticBanner size='none'>
            <div className='hero-text'>
                <Heading
                    type='h1'
                    text='News'
                />
            </div>
            <div className='cards row'>
                {newsList?.map((row) => {
                    return (
                        <div className='col-4 col-xs-12 mt-30'>
                            <div className='cards_item'>
                                {row?.img && (
                                    <div className={'cards__item__img'}>
                                        <img
                                            src={row.img}
                                            alt={row.alt || 'image'}
                                            style={{
                                                width: '350px',
                                                height: '250px',
                                                borderRadius: '2%',
                                            }}
                                        />
                                    </div>
                                )}
                                {row?.logo && (
                                    <img
                                        style={{ width: '100px', height: '30px' }}
                                        className='img-fluid'
                                        src={row.logo.img}
                                        alt=''
                                    />
                                )}
                                {row?.heading && (
                                    <div className='cards__item__header'>
                                        <Link href={row.link.href}>
                                            <h5 style={{ fontWeight: 'bold' }}>{row.heading}</h5>
                                        </Link>
                                    </div>
                                )}
                                {row.desc && (
                                    <div className='cards__item__content'>
                                        <Paragraph
                                            icon={row.descIcon}
                                            className='fs-sm m-0'>
                                            {row.desc.substring(0, 300)}
                                        </Paragraph>
                                    </div>
                                )}
                                {row.link && (
                                    <div className='cards__item__footer'>
                                        <a
                                            className='fs-xs mt-8'
                                            href={row.link.href}
                                            target='_blank'
                                            style={{ color: 'blue' }}>
                                            {row.link.text}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </StaticBanner>
    );
};

export default News;
