export const humanReadableNumber = (num, currency = false, opt = {}) => {
    let response = '';
    let suffix = '';
    let mutliplier = 1;
    if (num < 0) {
        mutliplier = -1;
        num *= -1;
    }
    if (!num) {
        response = 0;
    }
    if (num > 999 && num < 100000) {
        response = mutliplier * parseFloat((num / 1000).toFixed(2));
        suffix = 'K';
    } else if (num >= 10000000) {
        response = mutliplier * parseFloat((num / 10000000).toFixed(2));
        suffix = 'Cr';
    } else if (num >= 100000) {
        response = mutliplier * parseFloat((num / 100000).toFixed(2));
        suffix = 'L';
    } else if (num <= 999) {
        response = mutliplier * num;
    }
    if (opt.suffixOnly) {
        return suffix;
    }
    if (opt.numberOnly) {
        return response;
    }
    return currency ? `₹${response} ${suffix}` : `${response} ${suffix}`;
};

export const beautify = (str, flag = true) => {
    if (!flag) {
        return str;
    }
    if (str && typeof str === 'string' && str.indexOf('.') !== -1) {
        const chunks = str.split('.');
        str = chunks[chunks.length - 1];
    }
    if (typeof str === 'boolean') {
        return str ? 'Yes' : 'No';
    } else if (str && typeof str === 'string') {
        // If is_, remove and add suffix ?
        if (str.indexOf('is_') === 0) {
            return beautify(str.replace('is_', '') + ' ?');
        }

        // If nothing found
        const arr = str.replace(/[-_]/g, ' ').toLowerCase().split(' ');
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].substring(1);
        }
        return arr.join(' ');
    }
    return '';
};
