import React from 'react';
import Icon from './Icon';

const Paragraph = ({
    children,
    config,
    text = config?.text || '',
    html = config?.html,
    className = config?.className,
    icon = config?.icon,
}) => {
    if (icon && html) {
        html = `<Icon icon="${icon}" />${html}`;
    }
    return (
        <>
            {children ? (
                <p className={className}>
                    {icon && <Icon icon={icon} />}
                    {children}
                </p>
            ) : html ? (
                <p
                    className={className}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            ) : (
                <p className={className}>
                    {icon && <Icon icon={icon} />}
                    {text}
                </p>
            )}
        </>
    );
};

export default Paragraph;
