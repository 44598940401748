import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import Stats from '../components/molecules/Stats';
import Section from '../components/organisms/Section';
import StaticBanner from '../components/organisms/StaticBanner';
import PartnersScroll from '../components/templates/PartnersScroll';
import { STATS } from '../constants/stats';
import { DICT } from '../constants/dict';

const BNPL = () => {
    return (
        <>
            <StaticBanner size='none'>
                <div className='hero-text'>
                    <Heading type='h1'>Saral Pay Later</Heading>
                    <Paragraph>
                        Provides tailored financing solutions by offering immediate payments to an
                        Anchor Partner and credit to its customers with flexible repayment options.
                    </Paragraph>
                </div>
                <div className='hero-stats mb-40'>
                    <Stats
                        items={[
                            {
                                icon: 'community-line',
                                value: STATS.bnpl.anchors,
                                desc: DICT.current_anchors,
                            },
                            {
                                icon: 'user-line',
                                value: STATS.bnpl.borrowers,
                                desc: DICT.current_borrowers,
                            },
                            {
                                icon: 'article-line',
                                value: STATS.bnpl.invoices,
                                desc: DICT.current_invoices,
                            },
                            {
                                icon: 'send-plane-2-line',
                                value: STATS.bnpl.disbursed,
                                desc: DICT.total_amount,
                                currency: true,
                            },
                            {
                                icon: 'cash-line',
                                value: STATS.bnpl.aum,
                                desc: DICT.current_aum,
                                currency: true,
                            },
                        ]}
                    />
                </div>
                {/* <PartnersScroll
                    url='saral-pay-later'
                    type='live'
                /> */}
            </StaticBanner>
            <div className='separator'></div>
            <Container className='text-center'>
                <Heading type='h2'>Who can benefit from Saral Pay Later?</Heading>
                <Paragraph>
                    Anchor Partners' customers, e.g., dealers, sub-dealers and retailers who need
                    steady working capital. Saral Pay Later helps them manage their operational
                    capital requirements efficiently.
                </Paragraph>
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does BNPL work in Supply Chain Finance?',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'paragraph',
                                            config: {
                                                text: 'When a Seller sells goods to a Buyer on Day 0, BNPL (Buy Now Pay Later) helps provide immediate working capital requirements and manage the payables of the Buyer and receivables for the Seller.',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                config: {
                                    src: '/img/products/1.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does Saral Pay Later expedite the BNPL process?',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'paragraph',
                                            config: {
                                                text: 'SaralSCF expedites the payment process by paying directly to the Seller on Day 0 and offering up to 120 days of credit duration to the Buyer for repayment.',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                config: {
                                    src: '/img/products/2.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
            {/* <div className="separator"></div>
            <Container align="center" size="xl">
                <Heading>
                    <span className="color-bcpl-dark">Saral Pay Later</span> in 4 Simple Steps
                </Heading>
                <br /><br />
                <Image src='/img/products/pay-later.png' />
            </Container> */}
        </>
    );
};

export default BNPL;
