import './App.scss';
import AppRoutes from './app/routes/AppRoutes';

function App() {
    return (
        <div className='App'>
            <AppRoutes />
        </div>
    );
}

export default App;
