import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Cards from '../components/organisms/Cards';
import StaticBanner from '../components/organisms/StaticBanner';
const Compliance = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>Regulatory & Compliance</Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <Heading
                    className='text-center mb-20'
                    type='h3'>
                    Group Policies
                </Heading>
                <Cards
                    config={{
                        colClass: 'col-4 mb-20 col-xs-12',
                        items: [
                            {
                                // icon: 'file-mark-line',
                                heading: 'Environmental, Social and Governance (ESG) Policy',
                                desc: 'Document for all ESG initiatives for our internal operations and lending activities undertaken by Blacksoil',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/2.-BCPL-ESG-POLICY_Jan-23.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: 'Related Party Transaction (RPT) Policy',
                                desc: 'This policy is intended to ensure the proper approval and reporting of transactions as applicable, between the Company and any of its Related Party',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/14.-Policy-on-dealing-with-RPT.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: 'Measures on Information Security',
                                desc: 'The Cyber security management framework which is headed by the Chief Information Security Officer',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/4.-Measures-Taken-On-Information-Security.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: 'Whistle Blower Policy',
                                desc: 'define and establish the mechanism for employees, Directors and stakeholders of Blacksoil Capital Private Limited',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/3.-whistle-blower-policy.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: 'KYC/Anti-Money Laundering Policy',
                                desc: 'reviewed and revised by the Compliance Team with the Whole-Time Directors consultation and with approval of board of directors',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/17.-KYC-and-AML.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: 'Internal Guidelines on Corporate Governance',
                                desc: 'protecting the interests of all stakeholders, ensure accountability, inculcate integrity and promote long-term growth and profitability',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/12.-Guidelines-on-Corporate-Governance.pdf',
                                },
                            },
                        ],
                    }}
                />
                <Heading
                    className='text-center mb-20 mt-40'
                    type='h3'>
                    Policies & Annual Returns
                </Heading>
                <Cards
                    config={{
                        colClass: 'col-4 mb-20 col-xs-12',
                        items: [
                            {
                                // icon: 'file-mark-line',
                                heading: 'Grievance Redressal Policy',
                                desc: 'approved and mandated by Board of Directors.',
                                descIcon: 'article-line',
                                link: {
                                    text: 'View Policy',
                                    href: '/files/group-policies/Grievance-Redressal-Policy.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: '2020-21',
                                desc: 'Annual Return for the Financial Year 2020-21',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/fy_2020_21.pdf',
                                },
                            },
                            {
                                // icon: 'file-mark-line',
                                heading: '2021-22',
                                desc: 'Annual Return for the Financial Year 2021-22',
                                descIcon: 'article-line',
                                link: {
                                    text: 'Download File',
                                    href: '/files/fy_2021_22.pdf',
                                },
                            },
                        ],
                    }}
                />
            </Container>
        </>
    );
};
export default Compliance;
