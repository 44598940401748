import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Image from '../components/atoms/Image';
import StaticBanner from '../components/organisms/StaticBanner';
import Paragraph from '../components/atoms/Paragraph';
const Privacy = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Image
                            className='shape'
                            src='/img/shape-2.png'
                        />
                        <Heading type='h1'>Privacy Policy</Heading>
                        <Image
                            className='shape'
                            src='/img/shape-1.png'
                        />
                    </div>
                </Container>
            </StaticBanner>
            <Container size='md'>
                <Heading type='h5'>Effective Date: 19 June 2023</Heading>
                <Paragraph>
                    Thank you for choosing Saral technologies Private Limited ("we," "our," or
                    "us").{' '}
                </Paragraph>
                <Paragraph>
                    We respect the privacy of everyone who visits this website and are committed to
                    maintain the privacy and security of the personal information of all visitors to
                    this website. Our policy on the collection and use of personal information and
                    other information is outlined below.
                </Paragraph>
                <Paragraph>
                    We are committed to protecting your privacy and ensuring the security of your
                    personal information. This Privacy Policy outlines how we collect, use,
                    disclose, and protect the information you provide to us when you access our
                    website, use our services, or interact with us in any other way. By using our
                    services, you consent to the practices described in this Privacy Policy.
                </Paragraph>
                <ol className='p-0'>
                    <li>
                        <strong>Information we Collect</strong>
                        <ol>
                            <li>
                                Personal or Business Information We may collect personal
                                information, including but not limited to:
                                <ol>
                                    <li>
                                        <strong>Contact information</strong> (e.g., name, address,
                                        email address, phone number).
                                    </li>
                                    <li>
                                        <strong>Identification information</strong> (e.g.,
                                        government-issued identification documents, photo, PAN,
                                        AADHAR, GST, Invoices, specimen signatures, social security
                                        number).
                                    </li>
                                    <li>
                                        <strong>Financial information </strong>(e.g., bank account
                                        details, details arising out of our transaction documents of
                                        the financial institutions we work with, invoice financing
                                        details).
                                    </li>
                                    <li>
                                        <strong>Employment information</strong> (e.g., occupation,
                                        employer details).
                                    </li>
                                    <li>
                                        <strong>Transactional information</strong> (e.g., payment
                                        history, account activity, details included in the invoice
                                        and the transaction procured as per the transaction
                                        documents executed with our financial partners).
                                    </li>
                                    <li>
                                        Non-Personal Information. We may also collect non-personal
                                        information, such as:
                                        <ul>
                                            <li>
                                                Aggregated data regarding website usage, traffic
                                                patterns, and user demographics.
                                            </li>
                                            <li>
                                                Cookies, IP addresses, and other similar
                                                technologies used to enhance your experience on our
                                                website.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>How We Use Your Information</strong>
                        <ol>
                            <li>
                                Service Delivery We use your personal information to:
                                <ul>
                                    <li>
                                        Provide and improve our services, including processing
                                        transactions, managing accounts, and facilitating customer
                                        support.
                                    </li>
                                    <li>
                                        Customize and personalize your experience with our services.
                                    </li>
                                    <li>
                                        If you are borrower or channel partner/anchor of any of the
                                        financial institutions who engage our services, then your
                                        information may be used as per the terms of the transaction
                                        documents executed with the financial institutions and to
                                        monitor the terms of the transaction documents.{' '}
                                    </li>
                                    <li>
                                        Comply with legal obligations, enforce agreements, and
                                        protect against fraudulent activities.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Marketing and Communication with your consent, we may use your
                                personal information to:
                                <ul>
                                    <li>
                                        Send you promotional materials, newsletters, and offers
                                        about our products and services.
                                    </li>
                                    <li>
                                        Communicate with you regarding important updates, changes to
                                        our services, or security alerts.
                                    </li>
                                    <li>
                                        Conduct market research, analysis, and surveys to enhance
                                        our offerings.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Legal Compliance:
                                <p>
                                    We may use your information as necessary to comply with
                                    applicable laws, RBI guidelines applicable to the financial
                                    regulations, legal processes, or enforceable governmental
                                    requests.
                                </p>
                                <p>
                                    Except as set out in this privacy policy, we will not disclose
                                    any personally identifiable information without permission,
                                    unless our financial institutional partners and us are legally
                                    entitled or required to do so or if we believe that it is
                                    necessary to protect and/or defend our rights, property or
                                    personal safety etc.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Information Sharing and Disclosure</strong>
                        <ol>
                            <li>
                                Third-Party Service Providers
                                <p>
                                    We may share your personal information with trusted third-party
                                    service providers to assist us in delivering our services. These
                                    service providers may include Transunion CIBIL Limited who
                                    provide CIBIL score and other credit information company or
                                    corporate service providers who provide general information on
                                    the existing charge on a particular assets owned by the persons
                                    and the status of any litigations filed on such persons.
                                </p>
                            </li>
                            <li>
                                Business Transfers
                                <p>
                                    In the event of a merger, acquisition, or sale of our assets,
                                    your personal information may be transferred to the acquiring
                                    entity or third party involved. We will ensure that the
                                    receiving party agrees to process your information in accordance
                                    with this Privacy Policy.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Legal Requirements</strong>
                        <p>
                            We may disclose your personal information if required to do so by law or
                            in response to valid legal requests from public authorities.
                        </p>
                    </li>
                    <li>
                        <strong>Data Security</strong>
                        <p>
                            We employ industry-standard security measures to protect your personal
                            information from unauthorized access, alteration, or disclosure.
                            However, no data transmission or storage system can guarantee 100%
                            security. We cannot be held responsible for unauthorized access to your
                            information that is beyond our control.
                        </p>
                    </li>
                    <li>
                        <strong>Your Choices and Rights</strong>
                        <ol>
                            <li>
                                <strong>Opting Out</strong>
                                <p>
                                    You have the right to opt out of receiving marketing
                                    communications from us by following the instructions provided in
                                    each communication.
                                </p>
                            </li>
                            <li>
                                <strong>Access, Correction, and Deletion</strong>
                                <p>
                                    You have the right to access, correct, or delete your personal
                                    information held by us however no data shall be deleted for a
                                    period of certain time if you are having existing facility from
                                    the financial institutional partners unless they instruct us to
                                    do so or as required as per their policies. To exercise these
                                    rights, please contact us at grievanceredressal@saralscf.com.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>Cookie Policy</strong>
                        <ol>
                            <li>
                                What are Cookies? Cookies are small text files stored on your device
                                when you visit our website. They help improve your browsing
                                experience and allow us to analyse website traffic and usage
                                patterns.
                            </li>
                            <li>We Use We may use the following types of cookies:</li>
                            <ul>
                                <li>
                                    <strong>Essential Cookies:</strong> These cookies are necessary
                                    for the operation of our website and enable you to navigate and
                                    use its features. Without these cookies, certain functionalities
                                    may not be available.
                                </li>
                                <li>
                                    <strong>Analytics Cookies:</strong> These cookies collect
                                    information about how visitors use our website, such as the
                                    pages they visit, the links they click on, and the time spent on
                                    each page. We use this information to analyze and improve the
                                    performance of our website and enhance your user experience.
                                </li>
                                <li>
                                    <strong>Advertising Cookies:</strong> These cookies are used to
                                    deliver relevant advertisements to you and measure the
                                    effectiveness of our advertising campaigns. They track your
                                    browsing habits and help us tailor the ads you see based on your
                                    interests.
                                </li>
                                <li>
                                    <strong>Functionality Cookies:</strong> These cookies allow our
                                    website to remember choices you make (such as your username,
                                    language preference, or region) and provide enhanced, more
                                    personalized features.
                                </li>
                            </ul>
                        </ol>
                    </li>
                    <li>
                        <strong>Contact Us</strong>
                        <p>
                            If you have any questions, concerns, or requests regarding this Privacy
                            Policy or the handling of your personal information, please contact us
                            using the following details:
                        </p>
                        <a href='mailto:grievanceredressal@saralscf.com'>
                            grievanceredressal@saralscf.com
                        </a>
                        <p>We will respond to your inquiries as soon as possible.</p>
                    </li>
                    <li>
                        <strong>Change of Privacy Policy</strong>
                        <p>
                            We preserves the full rights to change/alter/amend/modify the contents
                            of the privacy policy from time to time without any prior notice or
                            intimation.
                        </p>
                    </li>
                </ol>
            </Container>
        </>
    );
};
export default Privacy;
