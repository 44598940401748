import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import { Link } from 'react-router-dom';

const Login = () => {
    return (
        <div className='login'>
            <Container>
                <div className='row'>
                    <div className='col-3 col-xs-12'>
                        <Heading type='h2'>Borrower App</Heading>
                        <Paragraph>
                            Streamlined KYC, seamless credit line activation, effortless withdrawal
                            requests, and instant UPI bank transfers for hassle-free repayments.
                        </Paragraph>
                        <Link
                            target='_blank'
                            to='https://app.saralscf.com'
                            className='btn'>
                            Login to App
                        </Link>
                        <br />
                        <br />
                    </div>
                    <div className='col-9 col-xs-12'>
                        <img
                            className='desktop-only'
                            src={`/img/login/app.png`}
                            alt=''
                        />
                        <img
                            className='mobile-only'
                            src={`/img/login/mobile-app.png`}
                            alt=''
                        />
                    </div>
                </div>
                <br />
                <div className='row'>
                    <div className='col-3 col-xs-12'>
                        <Heading type='h2'>Anchor Portal</Heading>
                        <Paragraph>
                            Seamlessly manage your borrowers, access real-time data, and enhance
                            communication.
                        </Paragraph>
                        <Link
                            target='_blank'
                            to='https://web.saralscf.com'
                            className='btn'>
                            Login to Anchor Portal
                        </Link>
                    </div>
                    <br />
                    <br />
                    <div className='col-9 col-xs-12'>
                        <img
                            className='desktop-only'
                            src={`/img/login/portal.png`}
                            alt=''
                        />
                        <img
                            className='mobile-only'
                            src={`/img/login/mobile-portal.png`}
                            alt=''
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Login;
