import { ValidationError, useForm } from '@formspree/react';
import React from 'react';
import Button from '../atoms/Button';

const ContactForm = () => {
    const [state, handleSubmit, reset] = useForm('mzbndver');
    if (state.succeeded) {
        console.log(state.succeeded);
    }
    return (
        <form
            onSubmit={handleSubmit}
            className='form'>
            <ValidationError
                prefix='Email'
                field='email'
                errors={state.errors}
            />
            <ValidationError
                prefix='Mobile'
                field='mobile'
                errors={state.errors}
            />
            <input
                className='form__control'
                type='text'
                name='name'
                placeholder='Name'
            />
            <input
                className='form__control'
                type='email'
                name='email'
                placeholder='E-Mail'
            />
            <input
                className='form__control'
                type='text'
                name='mobile'
                placeholder='Mobile Number'
            />
            <textarea
                className='form__control'
                placeholder='Write your Message here'></textarea>
            <Button disabled={state.submitting}>Request a Callback</Button>
        </form>
    );
};

export default ContactForm;
