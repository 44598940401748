export const newsList = [
    {
        img: 'https://static.businessworld.in/1597211152_uvZQ2f_Funds.jpg',
        heading: 'Supply Chain Finance Platform SaralSCF Disburses Over Rs 270 Cr In Q1 FY25',
        desc: 'Supply chain finance platform SaralSCF has reported outstanding growth in FY24, with a disbursement of over ₹940 Crore across 1.46 lakh invoices. Compared to the previous financial year, the company’s disbursement increased by 30%, and it processed double the number of invoices in FY24.',
        link: {
            text: 'View More',
            href: 'https://bwdisrupt.com/article/supply-chain-finance-platform-saralscf-disburses-over-rs-270-cr-in-q1-fy25-528192',
        },
        logo: {
            img: 'https://static.businessworld.in/bw-disrupt-logo.png',
        },
    },
    {
        img: 'https://www.siliconindia.com/news/newsimages/cXAtN5bC.jpg',
        heading: 'Supply Chain Finance Platform SaralSCF Disburses Over Rs 270 Crore in Q1FY25',
        desc: 'Supply chain finance platform SaralSCF has announced its financial results for the first quarter of the financial year 2025 (Q1FY25), showcasing robust growth across key performance metrics. The Company disbursed over Rs 270 Crore across 30K invoices during this period.',
        link: {
            text: 'View More',
            href: 'https://www.siliconindia.com/news/general/supply-chain-finance-platform-saralscf-disburses-over-rs-270-crore-in-q1fy25-nid-230966-cid-1.html#google_vignette',
        },
        logo: {
            img: 'https://s3.amazonaws.com/zaubatrademarks/e8b3cc74-2750-4ceb-926e-ad11ed6da6aa.png',
        },
    },
    {
        img: 'https://images.yourstory.com/cs/2/ebefb4a0b2a011edb86579ca40e04503/CopyofImageTaggingnoframesEditorialTeamMaster-1709114537121.jpg?mode=crop&crop=faces&ar=16%3A9&format=auto&w=2048&q=75',
        heading: "Blacksoil's SaralSCF completes pilot with disbursements worth Rs 1,500 Cr",
        desc: "Alternative lender BlackSoil's supply chain finance subsidiary SaralSCF has completed its pilot phase with more than Rs 1,500 crore disbursed to 16,500 borrowers since 2021, according to a statement.The B2B (business-to-business) platform offers tailored financing solutions to mid-size enterprises.",
        link: {
            text: 'View More',
            href: 'https://yourstory.com/2024/02/blacksoils-saralscf-ends-pilot-with-rs-1500-cr-disbursed',
        },
        logo: {
            img: 'https://images.yourstory.com/cs/images/homepage/headerV3_YS.svg',
        },
    },
    {
        img: 'https://assets.entrepreneur.com/content/3x2/2000/1717070107-Untitleddesign5.jpg?format=pjeg&auto=webp&crop=16:9&width=675&height=380',
        heading: "Innovation Meets Tradition To Shape India's Agricultural Future",
        desc: `Shubh Dayama, AVP – Business, SaralSCF, the supply chain arm of BlackSoil Group, a venture debut fund, opines that the sector is primed for further growth with increasing technology and the perspectives to be unlocked by education at the base level, "With changing climatic conditions, technological innovations are crucial for better adaptability, resilience, and meeting the ever-increasing food demand. Technology advancements in the AgriTech sector and its supply chain will be vital for tracking and tracing food products and reducing waste and degradation," he says.`,
        link: {
            text: 'View More',
            href: 'https://www.entrepreneur.com/en-in/news-and-trends/innovation-meets-tradition-to-shape-indias-agricultural/474884',
        },
        logo: {
            img: 'https://assets.entrepreneur.com/static/20190308065725-ENT-India-Logo-Black.svg',
        },
    },
    {
        img: 'https://mybigplunge.com/wp-content/uploads/2024/02/2148525342.jpg.webp',
        heading: 'BlackSoil’s SaralSCF Emerges to Transform Supply Chain Financing in India',
        desc: 'BlackSoil Group’s supply chain financing vertical, SaralSCF, has stepped out of stealth mode with impressive numbers. Having disbursed over ₹1,500 Crore and onboarded more than 110 Anchor Partners, SaralSCF is set to revolutionize India’s supply chain finance segment. The platform offers tailored financing solutions to mid-size enterprises, startups, and growth companies, addressing their diverse working capital requirements.',
        link: {
            text: 'View More',
            href: 'https://mybigplunge.com/business/msme/blacksoils-saralscf-emerges-to-transform-supply-chain-financing-in-india/',
        },
        logo: {
            img: 'https://mybigplunge.com/wp-content/uploads/2016/10/logo-nav-7.png',
        },
    },
    {
        img: 'https://bl-i.thgim.com/public/incoming/8i5suu/article68224991.ece/alternates/LANDSCAPE_1200/BL16_Think_trade_logistics.jpg',
        heading: 'Supply chain finance platform SaralSCF disbursement up 30% to ₹940 crore',
        desc: 'Supply chain finance platform SaralSCF has disbursed over ₹940 crore across 1.46 lakh invoices. The disbursement increased by 30 per cent, and the number of invoices has doubled compared to the previous financial year. SaralSCF’s assets under management (AUM) jumped by 30 per cent year-on-year.',
        link: {
            text: 'View More',
            href: 'https://www.thehindubusinessline.com/money-and-banking/supply-chain-finance-platform-saralscf-disburses-over-940-cr-in-fy24/article68224490.ece',
        },
        logo: {
            img: 'https://assetsbl.thehindubusinessline.com/theme/images/bl-online/logo.svg',
        },
    },
    {
        img: 'https://images.yourstory.com/cs/2/0a385fc03e6011e999df3d1594bbde2c/NewsCover-1688627874230.png?mode=crop&crop=faces&ar=16%3A9&format=auto&w=1920&q=75',
        heading: 'SaralSCF disburses Rs 940 Cr in FY24',
        desc: "BlackSoil Group's supply chain financing vertical SaralSCF has disbursed over Rs 940 crore across 1.46 lakh invoices in FY24, up 30% from the previous year. Established in 2018, ﻿Solinas﻿ focuses on developing robotic and AI solutions to address issues like pipeline defect detection and manual scavenging elimination. Solinas said it intends to use the funds to enhance its product range, expand its market presence, and further its goal of climate change adaptation by strengthening water and sanitation infrastructure resilience.",
        link: {
            text: 'View More',
            href: 'https://yourstory.com/2024/05/startup-news-and-updates-daily-roundup-may-28-2024-nykaa-maxvolt',
        },
        logo: {
            img: 'https://images.yourstory.com/cs/images/homepage/headerV3_YS.svg',
        },
    },
    {
        img: 'https://entrackr.com/storage/2024/03/Blacksoil.jpg',
        heading: 'SaralSCF Reports ₹940 Crore Disbursement in FY24',
        desc: 'Supply chain finance platform SaralSCF has reported outstanding growth in FY24, with a disbursement of over ₹940 Crore across 1.46 lakh invoices. Compared to the previous financial year, the company’s disbursement increased by 30%, and it processed double the number of invoices in FY24. SaralSCF’s asset under management (AUM) jumped by 30% year-on-year.',
        link: {
            text: 'View More',
            href: 'https://smestreet.in/infocus/saralscf-reports-inr-940-crore-disbursement-in-fy24-4705725',
        },
        logo: {
            img: 'https://img-cdn.thepublive.com/fit-in/580x326/filters:format(webp)/smstreet/media/agency_attachments/3LWGA69AjH55EG7xRGSA.png',
        },
    },
];
