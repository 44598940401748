import React from 'react';

const StaticBanner = ({ children, size = '' }) => {
    let className = 'static_banner';
    if (size === 'none') {
        className += ` static_banner-none`;
    }
    if (size === 'sm') {
        className += ` static_banner-sm`;
    }
    if (size === 'xs') {
        className += ` static_banner-xs`;
    }
    return (
        <div className={className}>
            <div className='static_banner__inner'>
                <div className='static_banner__inner__content'>{children}</div>
            </div>
        </div>
    );
};

export default StaticBanner;
