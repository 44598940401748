import React from 'react';

const Image = ({
    config,
    src = config?.src,
    alt = config?.alt || 'Image',
    radius = config?.radius,
    className = config?.className || '',
}) => {
    if (radius) {
        className += ` ${radius}`;
    }
    return (
        <>
            <img
                className={className}
                src={src}
                alt={alt}
            />
        </>
    );
};

export default Image;
