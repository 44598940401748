import React from 'react';
import Logo from '../atoms/Logo';
import Container from '../atoms/Container';
import Section from './Section';

const Footer = () => {
    const data = {
        logo: '/img/logo-white.svg',
    };
    return (
        <footer className='footer'>
            <Container>
                <Section
                    config={{
                        items: [
                            {
                                col: 3,
                                colXs: 12,
                                type: 'section',
                                config: {
                                    items: [
                                        {
                                            type: 'logo',
                                            src: data.logo,
                                        },
                                        {
                                            type: 'paragraph',
                                            text: 'Unit No. - 105 First Floor BPTP Park Centra, Jal Vayu Vihar, Sector 30, Gurugram, Haryana 122001',
                                        },
                                        {
                                            type: 'link',
                                            config: {
                                                text: 'Email: info@saralscf.com',
                                                href: 'mailto:info@saralscf.com',
                                            },
                                        },
                                        {
                                            type: 'paragraph',
                                            html: '<strong>CIN: </strong>U74999MH2020PTC420187',
                                        },
                                        {
                                            type: 'heading',
                                            config: {
                                                className: 'mt-20',
                                                type: 'h5',
                                                text: 'Registered NBFC Partners',
                                            },
                                        },
                                        {
                                            type: 'link',
                                            config: {
                                                className: 'my-10 color-bcpl',
                                                text: 'Blacksoil Capital Pvt. Ltd.',
                                                href: 'http://blacksoil.co.in/',
                                                target: '_blank',
                                            },
                                        },
                                        {
                                            type: 'link',
                                            config: {
                                                className: 'color-link-light',
                                                text: 'Niyogin Fintech Ltd.',
                                                href: 'https://www.niyogin.com/',
                                                target: '_blank',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 2,
                            },
                            {
                                col: 4,
                                colXs: 12,
                                type: 'navigation',
                                config: {
                                    heading: 'Quick Links',
                                    items: [
                                        { text: 'Home', href: '/' },
                                        { text: 'About Us', href: '/about' },
                                        {
                                            text: 'Saral Pay Later',
                                            href: '/saral-solutions/saral-pay-later',
                                        },
                                        {
                                            text: 'Vendor Financing',
                                            href: '/saral-solutions/saral-vendor-finance',
                                        },
                                        {
                                            text: 'Supply Chain Credit',
                                            href: '/saral-solutions/saral-supply-chain-credit',
                                        },
                                        { text: 'Contact Us', href: '/contact' },
                                    ],
                                },
                            },
                            {
                                col: 3,
                                colXs: 12,
                                type: 'navigation',
                                config: {
                                    heading: 'Other Links',
                                    items: [
                                        { text: 'Privacy Policy', href: '/privacy-policy' },
                                        {
                                            text: 'Terms & Conditions',
                                            href: '/terms-and-conditions',
                                        },
                                        {
                                            text: 'Regulatory & Compliance',
                                            href: '/regulatory-and-compliance',
                                        },
                                        {
                                            text: 'API Documentation',
                                            href: 'https://documenter.getpostman.com/view/9478456/TzRYe5Mf',
                                            target: '_blank',
                                        },
                                        {
                                            text: 'Android SDK',
                                            href: 'https://github.com/saraloan/retailersdk',
                                            target: '_blank',
                                        },
                                        {
                                            text: 'In the News',
                                            href: '/news',
                                        },
                                    ],
                                },
                            },
                        ],
                    }}
                />
            </Container>
        </footer>
    );
};

export default Footer;
