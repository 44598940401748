import React from 'react';
import Icon from '../atoms/Icon';
import CountUp from 'react-countup';
import { humanReadableNumber } from '../../helpers/str';

const Stats = ({ config, items = config?.items, className = config?.className }) => {
    const counter = (row) => {
        const num = humanReadableNumber(row.value, false, {
            numberOnly: true,
        });
        return (
            <CountUp
                decimals={num % 1 === 0 ? 0 : 2}
                end={num}
            />
        );
    };
    return (
        <div className={`icons row ${className}`}>
            {items &&
                items.length > 0 &&
                items.map((row) => {
                    return (
                        <div
                            key={row.desc}
                            className='col col-xs-6 icons__item'>
                            <Icon icon={row.icon} />
                            <div className='icons__item__value'>
                                {row.currency && <>₹</>}
                                {counter(row)}
                                {humanReadableNumber(row.value, false, { suffixOnly: true })}
                            </div>
                            <p className='icons__item__desc'>{row.desc}</p>
                        </div>
                    );
                })}
        </div>
    );
};

export default Stats;
