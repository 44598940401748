import React, { useState } from 'react';
import { PARTNERS } from '../../constants/partners';

const PartnersScroll = ({ url }) => {
    const [type, setType] = useState('live');
    const partners = PARTNERS;

    const listPartners = (partnerType, partnerUrl) => {
        return (
            <div className='clients'>
                {/* <div className='clients__nav'>
                    {['live', 'exited'].map((item) => {
                        return (
                            <span
                                key={item}
                                onClick={() => setType(item)}
                                className={`clients__nav__item ${item === type ? 'active' : ''}`}>
                                {beautify(item)}
                            </span>
                        );
                    })}
                </div> */}
                <h5 className='mb-20'>Anchor Partners</h5>
                <swiper-container
                    pagination-clickable={true}
                    slides-per-view='auto'
                    pagination={{ clickable: true }}>
                    {partners[partnerUrl][partnerType].map((partner, index) => {
                        return (
                            <swiper-slide key={index}>
                                <div
                                    className='clients__item'
                                    key={index}>
                                    <img
                                        src={`/img/partners/${partner.logo}`}
                                        alt=''
                                    />
                                </div>
                            </swiper-slide>
                        );
                    })}
                </swiper-container>
            </div>
        );
    };

    return (
        <>
            {url ? (
                listPartners(type, url)
            ) : (
                <>
                    {[
                        'saral-pay-later',
                        'saral-vendor-finance',
                        'saral-supply-chain-credit',
                        'all',
                    ].map((key) => {
                        return (
                            <React.Fragment key={`${type}_${key}`}>
                                {listPartners(type, key)}
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default PartnersScroll;
