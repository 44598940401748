import React from 'react';
import Icon from '../atoms/Icon';
import Image from '../atoms/Image';

const IconGroup = ({ config }) => {
    let className = 'icons row';
    if (config.className) {
        className += ` ${config.className}`;
    }
    return (
        <div className={className}>
            {config?.items.map((row, index) => {
                return (
                    <div
                        key={index}
                        className={`col icons__item ${config.itemClass || ''}`}>
                        {/* <Icon icon={row.icon} /> */}
                        <Image src={row.icon} />
                        <p className='icons__item__heading'>{row.heading}</p>
                        <p className='icons__item__desc'>{row.desc}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default IconGroup;
