import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import StaticBanner from '../components/organisms/StaticBanner';

const NotFound = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h1'>
                            <strong>404</strong>
                        </Heading>
                        <Heading type='h3'>
                            <strong>Page Not Found</strong>
                        </Heading>
                    </div>
                </Container>
            </StaticBanner>
        </>
    );
};

export default NotFound;
