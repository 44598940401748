import React from 'react';
import Badge from '../atoms/Badge';
import Heading from '../atoms/Heading';
import Icon from '../atoms/Icon';
import Image from '../atoms/Image';
import Link from '../atoms/Link';
import Paragraph from '../atoms/Paragraph';

const Cards = ({ config }) => {
    return (
        <div className={`cards row ${config.className || ''}`}>
            {config?.items?.map((row) => {
                return (
                    <div
                        className={config.colClass || 'col'}
                        key={row.heading}>
                        <div className='cards__item'>
                            {row.masterHeading && (
                                <div className='cards__item__header'>
                                    {row?.link?.href ? (
                                        <Link
                                            href={row.link.href}
                                            className='d-block'>
                                            <Heading
                                                className='m-0 text-center'
                                                type='h3'>
                                                {row.masterHeading}
                                            </Heading>
                                        </Link>
                                    ) : (
                                        <Heading
                                            className='m-0 text-center'
                                            type='h3'>
                                            {row.masterHeading}
                                        </Heading>
                                    )}
                                    {row.badge && <Badge text={row.badge} />}
                                </div>
                            )}
                            {row.img && (
                                <>
                                    {row?.link?.href ? (
                                        <Link href={row.link.href}>
                                            <div className={`cards__item__img ${row.imgClass}`}>
                                                <Image
                                                    src={row.img}
                                                    radius='rounded'
                                                />
                                            </div>
                                        </Link>
                                    ) : (
                                        <div className={`cards__item__img ${row.imgClass}`}>
                                            <Image
                                                src={row.img}
                                                radius='rounded'
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                            {row.icon && (
                                <>
                                    {row?.link?.href ? (
                                        <Link href={row.link.href}>
                                            <div className='cards__item__icon'>
                                                <Icon icon={row.icon} />
                                            </div>
                                        </Link>
                                    ) : (
                                        <div className='cards__item__icon'>
                                            <Icon icon={row.icon} />
                                        </div>
                                    )}
                                </>
                            )}
                            {row.heading && (
                                <div className='cards__item__header'>
                                    {row?.link?.href ? (
                                        <Link href={row.link.href}>
                                            <Heading
                                                className='m-0'
                                                type='h5'>
                                                {row.heading}
                                            </Heading>
                                        </Link>
                                    ) : (
                                        <Heading
                                            className='m-0'
                                            type='h5'>
                                            {row.heading}
                                        </Heading>
                                    )}
                                    {row.badge && <Badge text={row.badge} />}
                                </div>
                            )}
                            <div className='cards__item__subheader'>
                                {row.subheading && (
                                    <Heading
                                        className='m-0'
                                        type='h6'>
                                        {row.subheading}
                                    </Heading>
                                )}
                            </div>
                            {row.desc && (
                                <div className='cards__item__content'>
                                    <Paragraph
                                        icon={row.descIcon}
                                        className='fs-sm m-0'>
                                        {row.desc}
                                    </Paragraph>
                                </div>
                            )}
                            {row.ol && (
                                <div className='cards__item__content'>
                                    <ol>
                                        {row.ol.map((li, liIndex) => {
                                            return <li key={liIndex}>{li}</li>;
                                        })}
                                    </ol>
                                </div>
                            )}
                            {row.footer && (
                                <div className='cards__item__footer'>
                                    <Paragraph className='fs-xs m-0'>{row.footer}</Paragraph>
                                </div>
                            )}
                            {row.link && (
                                <div className='cards__item__footer'>
                                    <Link
                                        className='fs-xs'
                                        href={row.link.href}
                                        target='_blank'>
                                        {row.link.text}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Cards;
