import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Image from '../components/atoms/Image';
import Paragraph from '../components/atoms/Paragraph';
import StaticBanner from '../components/organisms/StaticBanner';
import Stats from '../components/molecules/Stats';
import Section from '../components/organisms/Section';
import { Link } from 'react-router-dom';
import PartnersScroll from '../components/templates/PartnersScroll';
import { STATS } from '../constants/stats';
import { DICT } from '../constants/dict';

const VendorFinancing = () => {
    return (
        <>
            <StaticBanner size='none'>
                <div className='hero-text'>
                    {/* <Image className="shape" src='/img/shape-2.png' /> */}
                    <Heading type='h1'>Saral Vendor Finance</Heading>
                    <Paragraph>
                        Provides flexible credit solutions to the buyer (Anchor), e.g.,
                        manufacturers, dealers, corporates, and distributors, within the supply
                        chain ecosystem.
                    </Paragraph>
                    {/* <Image className="shape" src='/img/shape-1.png' /> */}
                </div>
                <div className='hero-stats mb-40'>
                    <Stats
                        items={[
                            {
                                icon: 'community-line',
                                value: STATS.vendor.anchors,
                                desc: DICT.current_anchors,
                            },
                            {
                                icon: 'user-line',
                                value: STATS.vendor.borrowers,
                                desc: DICT.current_borrowers,
                            },
                            {
                                icon: 'article-line',
                                value: STATS.vendor.invoices,
                                desc: DICT.current_invoices,
                            },
                            {
                                icon: 'send-plane-2-line',
                                value: STATS.vendor.disbursed,
                                desc: DICT.total_amount,
                                currency: true,
                            },
                            {
                                icon: 'cash-line',
                                value: STATS.vendor.aum,
                                desc: DICT.current_aum,
                                currency: true,
                            },
                        ]}
                    />
                </div>
                {/* <PartnersScroll
                    url='saral-vendor-finance'
                    type='live'
                /> */}
            </StaticBanner>
            <div className='separator'></div>
            <Container className='text-center'>
                <Heading>Who can benefit from Saral Vendor Finance? </Heading>
                <Paragraph>Suppliers of the anchors can benefit from this product.</Paragraph>
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does a typical Vendor Finance work in Supply Chain Finance?',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'ul',
                                            config: {
                                                items: [
                                                    'In Vendor Finance in Supply Chain Finance, the Supplier (Vendor) becomes the borrower.',
                                                    'Vendor raises invoices to the Anchor for the goods sold.',
                                                    'A lender then finances the invoices. ',
                                                    "The lender disburses the funds directly into the Vendor's bank account.",
                                                    'The Anchor repays the loan to the lender. ',
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                config: {
                                    src: '/img/products/1.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does SaralSCF expedite the Vendor Financing process?',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'paragraph',
                                            config: {
                                                text: "Through SaralSCF, Vendor onboarding is a seamless activity which reduces time at Anchor's end to finance invoices raised to them by Vendors. Also, the customisable tech system ensures that anchor-specific needs are met, helping them achieve optimal results.",
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                config: {
                                    src: '/img/products/2.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
        </>
    );
};

export default VendorFinancing;
