import React from 'react';
import Paragraph from '../atoms/Paragraph';
import { objExcept } from '../../utils/obj';

const Paragraphs = ({ config }) => {
    return (
        <>
            {config?.items.map((item, index) => {
                return (
                    <Paragraph
                        key={index}
                        config={objExcept(config, ['items'])}
                        html={item}></Paragraph>
                );
            })}
        </>
    );
};

export default Paragraphs;
