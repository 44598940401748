import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Button from '../components/atoms/Button';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Paragraph from '../components/atoms/Paragraph';
import StaticBanner from '../components/organisms/StaticBanner';
import ContactForm from '../components/organisms/ContactForm';

const Contact = () => {
    return (
        <>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text mb-10'>
                        <Heading type='h1'>
                            Contact Saral<span className='color-bcpl-dark'>SCF</span>
                        </Heading>
                    </div>
                    <Paragraph>
                        We appreciate your interest and would love to hear from you. Whether you
                        have a question, feedback, or want to explore potential collaborations,
                        we're here to assist you. Please feel free to get in touch with us using our
                        Contact Form
                    </Paragraph>
                </Container>
            </StaticBanner>
            <Container>
                <div className='row'>
                    <div className='col-5 col-xs-12'>
                        <Heading type='h4'>Registered Address</Heading>
                        <Paragraph>
                            1203, Lodha Supremus, <br />
                            Senapati Bapat Marg, Lower Parel (W), <br />
                            Mumbai, Maharashtra - 400 013, India <br />
                        </Paragraph>
                        <Heading
                            className='mt-20'
                            type='h4'>
                            Corporate Office
                        </Heading>
                        <Paragraph>
                            Tower A, 5th Floor 502 <br />
                            Peninsula Corporate Park, Lower Parel (W) <br />
                            Mumbai, Maharashtra - 400013, India
                        </Paragraph>
                        <Heading
                            className='mt-20'
                            type='h4'>
                            Grievance Redressal/Nodal Officer
                        </Heading>
                        <Paragraph>
                            Gaurav Bagrodia
                            <br />
                            1203, Lodha Supremus, <br />
                            Senapati Bapat Marg, Lower Parel (W),
                            <br />
                            Mumbai, Maharashtra - 400 013, India <br />
                            <a href='tel:912224812200'>+91-22-2481-2200</a> <br />
                            <a href='tel:918828473446'>+91-88284-73446</a> <br />
                            <a href='mailto:nodalofficer@saralscf.com'>nodalofficer@saralscf.com</a>
                        </Paragraph>
                    </div>
                    <div className='col-7 col-xs-12'>
                        <ContactForm />
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Contact;
